<template>
  <u-dropdown :items="currentUserSettings">
    <u-button :icon="COMMON_ICONS.moreHorizontal" variant="outline" color="charcoal"/>
    <template #view-as>
      <u-radio-group @click.stop v-model="privacy" legend="View As" :options="viewAsOptions" :ui-radio="{help: 'text-xs'}">
        <template #legend>
          <span class="font-semibold">View As</span>
        </template>
      </u-radio-group>
    </template>
  </u-dropdown>
</template>

<script setup>
  const {user, showEditAction} = defineProps({
    user: {
      type: Object,
      required: true
    },
    showEditAction: Boolean
  });

  const emit = defineEmits(['on-view-as-change']);

  const {editProfile, editThumbnail, openDetailsModal, shareProfile} = useUserActions();

  const currentUserSettings = computed(() => [
    [
      showEditAction ? {
        label: 'Edit Profile',
        icon: COMMON_ICONS.edit,
        click: () => editProfile({ user })
      } : null,
      {
        label: 'View Info',
        icon: COMMON_ICONS.information,
        click: () => openDetailsModal({ user })
      },
      {
        label: 'Share Profile',
        icon: COMMON_ICONS.share,
        click: () => shareProfile({ user })
      },
      {
        label: 'Change Profile Photo',
        icon: 'i-ri-image-circle-line',
        click: () => editThumbnail({ user })
      },
      {
        label: 'Edit Preferences',
        icon: 'i-ri-settings-4-line',
        href: '/app/settings/preferences',
        external: true,
        class: 'no-underline'
      }
    ].filter(n => n),
    [
      {
        slot: 'view-as',
        class: 'bg-shell-200 text-charcoal cursor-default text-left',
        click: () => {}
      }
    ]
  ]);

  const privacy = defineModel('privacy');
  const viewAsOptions = [
    ALBUM_PERMISSION_LAYERS.private,
    ALBUM_PERMISSION_LAYERS.family,
    ALBUM_PERMISSION_LAYERS.public
  ];

</script>
